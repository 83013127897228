import endpoint from "@/_evoli/helper/endpoint.js";
import assign from "@/_evoli/helper/assign.js";
import permission from "@/_evoli/helper/permission";

export default {
  READ_Entities(store, payload) {
    let route = payload.route ? payload.route : "/v1/" + payload.entityName;
    let skeleton =
      typeof payload.skeleton === "undefined" ? true : payload.skeleton;
    if (payload.links) {
      store.commit("addLoading", {
        skeleton,
        method: "GET",
        route,
      });
    }

    if(!payload.force && !payload.links && payload.params?.pageName){
      let entities = store.getters.getEntities(payload.entityName, payload.params.pageName)
      if(entities.length > 0){
        return new Promise((resolve) => {
          resolve(entities)
        })
      }
    }

    return endpoint(store, "GET", route, payload.params, null, skeleton, true, null).then(
      (response) => {
        if(!response) return
        let items = response?.items ? [...response.items] : [];
        let meta = {
          limit: response.limit < 1 ? response.total : response.limit,
          total: response.total,
          page: response.page,
          pages: response.pages,
          order: response.order,
          sort: response.sort,
        };
        if (payload.links) {
          return assign(store, items, payload.links, skeleton).then(
            (result) => {
              store.commit("removeLoading", {
                skeleton,
                method: "GET",
                route,
              });
              store.commit("addEntities", {
                ...payload,
                meta,
                items: result,
              });
            }
          );
        } else {
          store.commit("addEntities", {
            ...payload,
            meta,
            items,
          });
        }
      }
    );
  },
  READ_Entity(store, payload) {
    let route = payload.route
      ? payload.route
      : "/v1/" + payload.entityName + "/" + payload._id;
    let skeleton =
      typeof payload.skeleton === "undefined" ? true : payload.skeleton;
    if (payload.links) {
      store.commit("addLoading", {
        skeleton,
        method: "GET",
        route,
      });
    }

    let entity = store.getters.getEntity(payload.entityName, payload._id)
    if(!payload.force && entity){
      return new Promise((resolve) => {
        resolve(entity)
      }).then((entity) => {
        if (payload.links) {
          return assign(store, entity, payload.links).then(() => {
            store.commit("removeLoading", {
              skeleton,
              method: "GET",
              route,
            });
          })
        }
      })
    }

    return endpoint(store, "GET", route, payload.params, null, skeleton, true, null).then(
      (response) => {
        let item = response;
        if (payload.links) {
          return assign(store, item, payload.links).then((result) => {
            store.commit("removeLoading", {
              skeleton,
              method: "GET",
              route,
            });
            store.commit("addEntity", {
              ...payload,
              item: result,
            });
          });
        } else {
          store.commit("addEntity", {
            ...payload,
            item,
          });
        }
        return response
      }
    );
  },
  CREATE_Entity(store, payload) {
    let route = payload.route
      ? payload.route
      : "/v1/" + payload.entityName;
    return endpoint(
      store,
      "POST",
      route,
      {},
      payload.values,
      typeof payload.skeleton === "undefined" ? true : payload.skeleton,
      true,
      null,
      payload.apiKey
    ).then((res) => {
      if (
        (typeof payload.toast === "undefined" || payload.toast) &&
        res.response
      ) {
        let content = payload.content || "Gespeichert";
        if (res.response.recreated) content = "Wiederhergestellt";
        if (res.response.suggestion) content = "Zur Auswahl hinzugefügt";
        store.commit("snackbar/open", {
          content,
          color: "success",
          icon: "mdi-check",
        });
      }

      let location = res.location.split("/")

      store.commit("addEntity", {
        ...payload,
        item: {
          ...payload.values,
          _id: location[location.length -1]
        },
      });

      return res;
    });
  },
  UPDATE_Entity(store, payload) {
    return endpoint(
      store,
      "PATCH",
      payload.route || "/v1/" + payload.entityName + "/" + payload.values._id,
      {},
      payload.values,
      typeof payload.skeleton === "undefined" ? true : payload.skeleton
    ).then((res) => {
      if (typeof payload.toast === "undefined" || payload.toast) {
        store.commit("snackbar/open", {
          content: "Gespeichert",
          color: "success",
          icon: "mdi-check",
        });
      }
      store.commit("updateEntity", payload);
      return res;
    });
  },
  DELETE_Entity(store, payload) {
    if(typeof payload.toast !== "undefined" && !payload.toast && permission(store, ["ADMIN"])){
      return endpoint(
        store,
        "DELETE",
        "/v1/" + payload.entityName + "/" + payload._id,
        null,
        null,
        typeof payload.skeleton === "undefined" ? true : payload.skeleton,
      ).then((res) => {
        store.commit("snackbar/open", {
          content: "Gelöscht",
          color: "error",
          icon: "mdi-check",
        })
        return res
      })
    }
    return store.commit("snackbar/open", {
      content: "Wollen Sie wirklich löschen?",
      buttonLabel: "Ja",
      closeLabel: "Nein",
      color: "error",
      callback: (value) => {
        if (value) {
          Promise.all([
            endpoint(
              store,
              "DELETE",
              "/v1/" + payload.entityName + "/" + payload._id,
              null,
              null,
              typeof payload.skeleton === "undefined" ? true : payload.skeleton,
            )
          ])
            .then(() =>
              store.commit("snackbar/open", {
                content: "Gelöscht",
                color: "error",
                icon: "mdi-check",
              })
            )
            .then(payload.callback)
            .catch(payload.callback);
        }
      },
    });
  },
  DOWNLOAD_Entity(store, payload) {
    let route = payload.route
      ? payload.route
      : "/v1/" + payload.entityName + "/" + payload._id + "/download";
		store.commit("addLoading", {
      skeleton: false,
      method: payload.method || "GET",
      route: "DOWNLOAD_Entity",
    });
    return endpoint(
      store,
      payload.method || "GET",
      route,
      payload.params,
      payload.values,
      false,
      false,
      "blob"
    ).then(async (response) => {
      const contentDisposition = response.headers.get("Content-Disposition");
      const fileName = contentDisposition.split("filename=")[1];
      const fileContent = await response.blob();
      if (payload.openInNewTab) {
							store.commit("removeLoading", {
        skeleton: false,
        method: payload.method || "GET",
        route: "DOWNLOAD_Entity",
      });
							return window
									.open(window.URL.createObjectURL(fileContent), "_blank")
									.focus();
						}
      const link = document.createElement("a");
      link.href = URL.createObjectURL(fileContent);
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(link.href);
      store.commit("removeLoading", {
							skeleton: false,
							method: payload.method || "GET",
							route: "DOWNLOAD_Entity",
					});
    });
  },
  COPY_Entity(store, payload) {
			store.commit("addLoading", {
				skeleton: false,
				method: "POST",
				route: "COPY_Entity",
			});
    let route = "/v1/" + payload.entityName + "/" + payload._id + "/copy";
    return endpoint(store, "POST", route, null, payload.values, false).then((res) => {
      store.commit("removeLoading", {
        skeleton: false,
        method: "POST",
        route: "COPY_Entity",
      });
      let location = res.location.split("/");
      let id = location[location.length - 1];
      if(!payload.noNewTab){
        window.open("/" + payload.entityName.toLowerCase() + "/" + id, "_blank").focus();
      }
    });
  },
  FINISH_Entity(store, payload) {
    let route =
      "/v1/" + payload.entityName + "/" + payload.values._id + "/finish";
    return endpoint(store, "POST", route, null, payload.values).then((res) => {
      store.dispatch("READ_Entity", {
        entityName: payload.entityName,
        _id: payload.values._id,
        force: true
      }).then(() => {
        store.dispatch("form/init", {
          form: payload.entityName + "Form",
          item: store.getters.getEntity(payload.entityName, payload.values._id)
        });

        store.commit("snackbar/open", {
          content: "Abgeschlossen",
          color: "success",
          icon: "mdi-check",
        });
      })
      return res;
    });
  },
  CANCEL_Entity(store, payload) {
    let route =
      "/v1/" + payload.entityName + "/" + payload.values._id + "/cancellation";
    return endpoint(store, "POST", route, null, payload.values).then((res) => {
      store.commit("snackbar/open", {
        content: "Widerrufen",
        color: "success",
        icon: "mdi-check",
      });
      store.commit("updateEntity", payload);
      return res;
    });
  },
  REPLACE_PLACEHOLDER_Entity(store, payload) {
    return endpoint(
      store,
      "POST",
      "/v1/" + payload.entityName.toLowerCase() + '/' + payload._id + '/placeholder',
      {
        type: payload.type
      },
      null,
      false
    ).then((res) => {
      store.commit("snackbar/open", {
        content: "Platzhalter ersetzt",
        color: "success",
        icon: "mdi-check",
      });
      return res;
    });
  },
  REPLACE_Entity(store, payload) {
    return endpoint(
      store,
      "POST",
      "/v1/" + payload.entityName.toLowerCase() + '/' + payload._id + '/replace',
      {},
      {newEntitiy: payload.newEntitiy},
      false
    ).then((res) => {
      store.commit("snackbar/open", {
        content: "Ersetzt",
        color: "success",
        icon: "mdi-check",
      });
      return res;
    });
  },
  CREATE_FILE(store, payload) {
    return endpoint(
      store,
      "POST",
      "/v1/" + payload.entityName.toLowerCase() + '/' + payload._id + '/file',
      {
        type: payload.type
      },
      null,
      true
    ).then((res) => {
      store.commit("snackbar/open", {
        content: "Datei erstellt",
        color: "success",
        icon: "mdi-check",
      });
      return res;
    });
  },
  ANONYMIZE_Entity(store, payload) {
    return endpoint(
      store,
      "POST",
      "/v1/documents/anonymize",
      {},
      payload,
      false
    ).then(() => {
      store.commit("snackbar/open", {
        content: "Dateien werden im Hintergrund pseudonymisiert",
        color: "success",
        icon: "mdi-check",
      })
    })
  },
  STOP_ANONYMIZE_Entity(store, payload) {
			return endpoint(
					store,
					"POST",
					"/v1/documents/stopanonymize",
					{},
					payload,
					null,
					false
			)
	},		
  ANONYMIZE_STATUS_Entity(store, payload) {
    return endpoint(
      store,
      "GET",
      "/v1/documents/anonstatus",
      payload.params,
      null,
      false
    )
    .then(res => {
      return res
    })
  },
  ANALYZE_Document(store, document) {
    return endpoint(
      store,
      "GET",
      "/v1/documents/" + document._id + "/analyze",
      null,
      null,
      false
    )
  },
	DOWNLOAD_CHUNKED_FILE(store, payload) {

    store.commit("setProgress", {
      type: "download",
      value: 0.1
    })

		store.commit("addLoading", {
			skeleton: false,
			method: "GET",
			route: "DOWNLOAD_CHUNKED_FILE",
		});


		const downloadFilePart = async (part, tempname = "", totalParts) => {
      return endpoint(
        store,
        "GET",
        payload.route,
        payload.params,
        null,
        false,
        false,
        null,
        {
          part: part.toString(),
          tempname: tempname
        },
      ).then(res => {
        if(part > 0){
          store.commit("setProgress", {
            type: "download",
            value: part/totalParts * 100
          })
        }
        return res
      })
    };

    const downloadFile = async () => {
      const response = await downloadFilePart(0)
      const totalParts = parseInt(response.headers.get('max-parts')) // Get the total number of parts from the first response
      const tempname = response.headers.get('temp-name')
      const mimeType = response.headers.get('Content-Type')
      let filename = response.headers.get('Content-Disposition')
      filename = decodeURI(filename.split("filename=")[1])

      const downloadPromises = [await response.blob()]
      for (let part = 1; part <= totalParts; part++) {
        downloadPromises.push((await downloadFilePart(part, tempname, totalParts)).blob())
      }

      store.commit("removeLoading", {
        skeleton: false,
        method: payload.method || "GET",
        route: "DOWNLOAD_CHUNKED_FILE",
      });
      store.commit("setProgress", {
        type: "download",
        value: 0
      })

      try {
        const fileParts = await Promise.all(downloadPromises);
        const mergedFile = new Blob(fileParts, { type: mimeType })
								if (payload.openInNewTab) {
									return window
									.open(window.URL.createObjectURL(mergedFile), "_blank")
									.focus();
								}
        const downloadLink = document.createElement('a')
        downloadLink.href = URL.createObjectURL(mergedFile)
        downloadLink.download = filename
        downloadLink.click()
      } catch (error) {
        console.error('Error downloading file:', error)
      }
    };

    downloadFile();
  },
	INVOICE_Entity(store, payload) {
		let route = payload.route
				? payload.route
				: "/v1/" + payload.entityName;
		return endpoint(
				store,
				"POST",
				route,
				{},
				payload.values,
				false,
				true,
				null,
		)
	},
  INVOICE_DOWNLOAD_Entity(store, payload) {
		let route = payload.route
				? payload.route
				: "/v1/" + payload.entityName;
		return endpoint(
				store,
				"POST",
				route,
				{},
				payload.values,
				false,
				true,
				null,
				).then(async (response) => {
          const contentDisposition = response.headers.get("Content-Disposition");
          const fileName = contentDisposition.split("filename=")[1];
          const fileContent = await response.blob();
          const link = document.createElement("a");
          link.href = URL.createObjectURL(fileContent);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
          store.commit("removeLoading", {
              skeleton: false,
              method: payload.method || "GET",
              route: "INVOICE_DOWNLOAD_Entity",
          });
        })
        .catch(() => {
          // console.log(res)
        });
	}
};
